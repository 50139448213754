//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  layout(context) {
    const path = context.params.pathMatch || 'home'
    const minimal = context.store.state.options.minimalLayouts
    if(minimal.indexOf(path.replace(/\/$/, '')) > -1) {
      return 'minimal'
    } else {
      return 'default'
    }
  },
  head() {
    return this.$metadata(this.story)
  },
  computed: {
    ...mapState([ 'location', 'order', 'user', 'menu', 'options' ])
  },
  async validate({ $storyblok, params }) {
    return await $storyblok.getStory(params.pathMatch || 'home')
  },
  async asyncData({ $storyblok, params }) {
    let story = await $storyblok.getStory(params.pathMatch || 'home')
    return {
      story
    }
  },
  async mounted() {
    if(this.story.content.require_location && !this.location) {
      this.$store.commit('setRedirect', `/${this.$route.params.slug}`)
      this.$router.push(this.options.locationsPage)
    } else if(this.story.content.require_order && !this.order) {
      this.$store.commit('setRedirect', `/${this.$route.params.slug}`)
      this.$router.push(this.options.orderPage)
    } else if(this.story.content.require_signin && !this.user) {
      this.$store.commit('setRedirect', `/${this.$route.params.slug}`)
      this.$router.push(this.options.signinPage)
    } else if(this.story.content.require_signout && this.user) {
      this.$store.commit('setRedirect', `/${this.$route.params.slug}`)
      this.$router.push(this.options.accountPage)
    } else {
      this.$storybridge(() => {
        const storyblokInstance = new StoryblokBridge()
        storyblokInstance.on(['input', 'published', 'change'], (event) => {
          if(event.action === 'input') {
            if(event.story.id === this.story.id) {
              this.story.content = event.story.content
            }
          } else {
            window.location.reload()
          }
        })
      })
    }
    if(this.story.content.product_page) {
      let id = this.$route.query.id
      let defaults = []
      let quantities = []
      if(this.$route.query.cid) {
        this.menu.categories.map(c => {
          c.products.map(p => {
            if(p.id == id) {
              id = p.sku
            }
          })
        })
        this.order.products.map(p => {
          if(this.$route.query.cid == p.id) {
            p.choices.map(c => {
              defaults.push(c.option_id)
              quantities.push(c.quantity)
            })
          }
        })
      }
      
      this.$store.dispatch('product/getProduct', {
        id,
        defaults,
        quantities
      })
    } else {
      this.$store.dispatch('product/clearProduct')
    }
    if (this.story.content.white_header) {
      this.$store.commit('setOpaque', true)
    } else {
      this.$store.commit('setOpaque', false)
    }
  }
}
